import {inject} from 'vue';
import {useQuery} from '@tanstack/vue-query';

export const useAbsenceRequestRequireApprovalQuery = (companyId, policyId) => {
    const {$api} = inject('jet');

    return useQuery({
        queryKey: ['getAbsenceRequestRequireApproval', companyId, policyId],
        queryFn: () => {
            return $api.absenceRequestKindSettings
                .requiresApprovalCodenames({company: companyId, policy: policyId})
                .then(response => response.data)
                .catch($api.end);
        },
        placeholderData: [],
    });
};
